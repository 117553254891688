import apiClient from '@/helpers/axios.js';
import { store } from '../store/index';

export const travellerService = {
	fetchTraveller,
	editGuestTraveller,
	fetchUserTravellers,
	saveTraveller,
	removeTraveller,
	fetchTripTravellers,
	addRelatedTraveller,
};

// New required functions from backend
/**
 * Fetch and return a passenger by the passenger ID
 * @param {*} pax_id 
 * @returns Passenger Data
 */
function fetchTraveller(pax_id) {
	var data = { 
		pax_id: pax_id, 
	}
	return apiClient.post('/fetch_passenger', data, {
		headers: store.getters['account/getAuthHeader']
	});
}

// New functions made from existing endpoints
// This edits the passenger based on the passenger ID - therefore will update the passenger details EVERYWHERE that that pax is used (e.g. multiple different flights)
function editGuestTraveller(passenger_id, pax_data) { 
	var data = { 
		passenger_id: passenger_id, 
		data: pax_data
	}
	
	return apiClient.post('/modify_passenger', data, {
		headers: store.getters['account/getAuthHeader']
	});
}
// Fetches all travellers related to a user
function fetchUserTravellers() {
	return apiClient.get('/get_users_pax', {
		headers: store.getters['account/getAuthHeader']
	});
}
// SavePassenger does two things automatically - create new Pax AND assign it the the given trip+trip_item
function saveTraveller(traveller_id = false, trip_id = false, trip_item_id = false, newPax = false, relatedTripItemData = false) {
	var data = { 
		id: traveller_id, 
		trip_id: trip_id, 
		trip_item_id: trip_item_id,
		data: newPax
	}

	if(relatedTripItemData) {
		if(relatedTripItemData.relatedAdult) {
			data.related_passenger_id = relatedTripItemData.relatedAdult;
		}
	}
	return apiClient.post('/save_passenger', data, {
		headers: store.getters['account/getAuthHeader']
	});
}
// removePassenger deletes the link between the passenger and the Trip Item (it does NOT delete the passenger)
function removeTraveller(passenger_id, trip_id, trip_item_id) {
	var data = { 
		passenger_id: passenger_id, 
		trip_id: trip_id,
		trip_item_id,
	}
	
	return apiClient.post('/remove_passenger', data, {
		headers: store.getters['account/getAuthHeader']
	});
}
// Fetch all passengers associated with a trip
function fetchTripTravellers(trip_id, trip_item_id = false) {
	var data = { 
		trip_id: trip_id,
	}
	if(trip_item_id) {
		data.trip_item_id = trip_item_id;
	}
	
	return apiClient.post('/fetch_passengers', data, {
		headers: store.getters['account/getAuthHeader']
	});
}
// Adds a "related traveller" to a pax. E.g. the adult associated with an infant for a flight.
function addRelatedTraveller(passenger_id, trip_id, trip_item_id, related_passenger_id) { // This edits the passenger based on the passenger ID - therefore will update the passenger details EVERYWHERE that that pax is used (e.g. multiple different flights)
	var data = { 
		passenger_id: passenger_id, 
		trip_id: trip_id,
		trip_item_id,
		related_passenger_id,
	}
	
	return apiClient.post('/add_related_passenger', data, {
		headers: store.getters['account/getAuthHeader']
	});
}



// Old functions 







