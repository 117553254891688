export default {
	// event is the name of the event, properties are the key value pairs of extra data to send
	event(event, properties) {
		try {
			let dataLayerValues = { ...{ 'event' : event }, ...properties}; // Combine together for dataLayer
			window.dataLayer.push(dataLayerValues);
		} catch(e) {
			this.$rollbar.error("Tracking 2: " + e);
			console.error(e);
		}
	},
	// Specific to ecommerce events. Ultimately just calls the event function, but takes care of the data mapping for trip items.
	ecommerce(event, ecommerce, tripItems = [], properties = {}) {
		try {
			window.dataLayer.push({ ecommerce: null }); // Ensure nothing interacts with the previous ecommerce data
			this.event(event, {
				...properties,
				ecommerce: {
					...ecommerce,
					items: tripItems.map(item => {
						if(item.type == "flight") {
							return {
								item_id: item.id,
								item_name: item.name,
								price: item.price,
								quantity: 1, // Always 1, because this multiples the price? So don't use traveller qty?
								traveller_quantity: item.travellers.length, // Custom field to store the number of travellers
								affiliation: item.id, // We reuse the affiliate field to store the trip item ID. TODO: Kris is looking into what to put here
								item_brand: item.data.flightsDetails.carrier.name,
								item_category: "Flight",
								item_category2: "Origin - " + item.data.flightsDetails.origin, // We use the item categories for origin and destination
								item_category3: "Destination - " + item.data.flightsDetails.destination,
							}
						} else if(item.type == 'tour') {
							return {
								item_id: item.data.tourID,
								item_name: item.name,
								price: item.price,
								quantity: 1, // Always 1, because this multiples the price? So don't use traveller qty?
								traveller_quantity: item.travellers.length, // Custom field to store the number of travellers
								affiliation: item.id, // We reuse the affiliate field to store the trip item ID.
								item_brand: item.data.rawTourData.booking_companies.join(', '),
								item_category: "Tour",
								item_category2: "Start - " + item.data.startLocation.name,
								item_category3: item.data.endLocation ? "End - " + item.data.endLocation.name : "End - " + item.data.startLocation.name,
							}
						}
					})
				}
			});
		} catch(e) {
			this.$rollbar.error("Tracking 3: " + e);
			console.error(e);
		}
	}
}