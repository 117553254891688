<template>
	<div class="result" :class="type">
		<div class="wrapper">
			<div class="icon-section">
				<img v-if="type == 'success'" src="@/assets/result-icons/success.png" alt="Success Icon"/>
				<img v-else-if="type == 'fail'" src="@/assets/result-icons/fail.png" alt="Fail Icon"/>
				<img v-else src="@/assets/result-icons/neutral.png" alt="Error Icon"/>
			</div>
			<div class="message-section" v-html="message">
			</div>
		</div>
	</div>
</template>

<script>

export default {
	props: {
		// Options: success, neutral, fail
		type: {
			type: String,
			default: "neutral",
		},
		// Message displayed below the icon
		message: {
			type: String,
			default: "",
		},
	},
	

}
</script>

<style scoped>
	.result {
		width: 221px;
	}
	.result .wrapper {
		width: 100%;
		position: relative;
	}
	.result .icon-section,
	.result .message-section {
		border-radius: 25px;
	}	
	.result .icon-section {
		aspect-ratio: 221 / 175;
		width: 100%;
		position: absolute;

		background-color: #898F98;
	}
	.result .icon-section img {
		width: 116%;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: 12%;
	}
	.result .message-section {
		width: 100%;
		position: relative;
		/* top: -175px; */
		z-index: -1;
		padding-top: 190px;
		padding-bottom: 20px;
		padding: 86% 10px 7% 10px;
		box-sizing: border-box;
		background-color: #B2B4B7;

		color: black;
		font-size: 12px;
		text-align: center;
	}

	/* Colors and icons */
	.result.success .icon-section {
		background-color: #06D6A0;
	}
	.result.success .message-section {
		background-color: #07E1A8;
	}
	.result.success .icon-section img {
		width: 101%;
	}

	.result.fail .icon-section {
		background-color: #FA5252;
	}
	.result.fail .message-section {
		background-color: #F88383;
	}
	.result.fail .icon-section img {
		width: 101%;
	}

</style>