import contentService from '@/services/contentService.js'

const state = {
    blogs: [],
};

const actions = {
    fetchBlogs({ commit }) {
		return contentService.latestPosts()
			.then(response => {
				commit('SET_BLOGS', response.data.data);
				return true;
			},
			error => {
				return Promise.reject(error);
			});
    },
    
};

const mutations = {
    SET_BLOGS(state, blogs) {
        state.blogs = blogs;
    },
};

export const blogs = {
    namespaced: true,
    state,
    actions,
    mutations
};