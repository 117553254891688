import helpers from '@/helpers/helpers.js';
import { tripService } from '@/services/tripService.js';
import { searchService } from '@/services/searchService.js';
import { PAX_INFANT_MAX_AGE, PAX_ADULT_MIN_AGE } from '@/helpers/constants.js';

const state = {
	trips: {
		default: {
			name: 'Default',
			items: 
				{
				// type: 'flights'
				// 	result_key: null,
				// 	outbound_key: null, // ???
				// 	return_key: null, // ???
				// 	// Any other data that may be useful to have for details page (BEFORE details endpoint is fired when the page is loaded). Flight times etc?
				// },
				// If more than one flight objects, go here.
				},
		},
		// Other 'custom' trips to be added here
	},
	backendTrips: [],

	// Selected values for adding to basket
	selected: {
		flight: false,
		tour: false,
	}
}

const getters = {
	getBasketData(state) {
		return state;
	},
}

const mutations = {
	SET_BASKET_DATA(state, data) {
		state.trips = data.trips;
	},
	SET_BACKEND_TRIPS_DATA(state, data) {
		state.backendTrips = data;
	},
	SET_SELECTED_TRIP(state, tripID) {
		state.selected.tripID = tripID;
	},
	SET_SELECTED_FLIGHT(state, payload) {
		state.selected.flight = {
			outboundKey: payload.outboundKey,
			returnKey: payload.returnKey,
			fareData: payload.fareData,
			airline: payload.airline,
			origin: payload.origin,
			destination: payload.destination,
			departure: payload.departure,
		};
	},
	SET_SELECTED_TOUR(state, payload) {
		state.selected.tour = {
			tourID: payload.tourID, 
			departureID: payload.departureID, 
			roomCode: payload.roomCode,
			date: payload.date,
			fareKey: payload.fareKey
		};
	},
}
const actions = {
	initBasket({ commit, getters, dispatch, rootGetters }) {
		// Check localstorage for trips
		var localData = localStorage.getItem('trips');
		if(!localData || localData == "undefined") {
			dispatch('saveBasket'); // Create blank local basket
			var localData = localStorage.getItem('trips');
		}
		localData = JSON.parse(localData);
		
		// Check if user is logged in.
		if(rootGetters['account/isLoggedIn']) {
			// If user has default trip
			if(localData && localData.hasOwnProperty("trips") && Object.keys(localData.trips.default.items).length > 0) {
				// Save default trip to backend and wipe local data. If the user has already named it locally, use that as the name
				var items = localData.trips.default.items;
				var data = [];
				for(let key in items) {
					data.push({
						trip_id: false, // This gets overwritten during the recursive saveToTrip function with the first returned trip_id
						type: items[key].type,
						data: {
							outbound_key: items[key].outbound_key,
							return_key: items[key].return_key,
							fare: items[key].fare,
							qty: items[key].qty,
							airline: items[key].airline,
							origin: items[key].origin,
							destination: items[key].destination,
							price: items[key].price,
						}
					});
				}
				if(data.length > 0) {
					// Recursively call saveToTrip in order to store ALL basket data to a backend Trip
					tripService.saveToTripRecursive(data)
					.then(
						response => { // response here is an array of all responses from the recursive call.
							var localData = localStorage.getItem('trips');
							localData = JSON.parse(localData);
							commit('SET_BASKET_DATA', localData); // Update state now that the localdata has been wiped (inside TripService)
							dispatch('fetchTrips');
						},
						error => {
						}
					);
				}
			} else {
				dispatch('fetchTrips');
			}
		} else {
			// Else (not logged in) - hold local data in state.
			commit('SET_BASKET_DATA', localData);
		}
		
	},
	fetchTrips({ commit, rootGetters }) {
		if(rootGetters['account/isLoggedIn']) {
			return tripService.fetchTrips()
				.then(
					response => {
						commit('SET_BACKEND_TRIPS_DATA', response.data.data);
						return true;
					},
					error => {
						return Promise.reject(error);
					}
				);
		}
		return Promise.reject("User not logged in");
	},
	saveBasket({ rootGetters, dispatch, getters }) { // Remove this separate function? Save basket ONLY for relevant updated trip, direct in the addToBasket function?
		// IF USER NOT LOGGED IN, SAVE TO LOCAL STORAGE. OTHERWISE DON'T.
		if(!rootGetters['account/isLoggedIn']) {
			// Update localstorage to match current vuex state
			let basketData = getters.getBasketData;
			// Save local data 
			localStorage.setItem('trips', JSON.stringify(basketData));

			var localData = localStorage.getItem('trips');
		} else {
			// Simply update the state to the latest from the backend
			dispatch('fetchTrips');
		}
	},
	async addToBasket({ commit, getters, dispatch, rootGetters }, data) { // locally saved trip is always "default", as we only allow one local 'default' trip for now.
		if(rootGetters['account/isLoggedIn']) {
			var s_data = {
				trip_id: data.trip_id, 
				type: data.type,
			}
			if(data.type == "flight") {
				s_data.data = {
					outbound_key: data.outbound_key,
					return_key: data.return_key,
					fare: data.fare,
					airline: data.airline,
					origin: data.origin,
					destination: data.destination,
					price: data.price,
					date: data.date, // Rough date for ordering purposes - could be wrong if date changes etc.
				}
			} else if(data.type == "tour") {
				s_data.data = {
					tour_id: data.tourID,
					departure_id: data.departureID,
					room_code: data.roomCode,
					price: data.price,
					date: data.date, // Rough date for ordering purposes - could be wrong if date changes etc.
					fare_key: data.fareKey,
				}
			}
			return tripService.saveToTrip(s_data)
				.then(
					response => {
						dispatch('saveBasket');
						return response;
					},
					error => {
						dispatch('alert/error', error, { root: true });
					}
				);
		} else {
			// Add item to local basket
			let basketData = getters.getBasketData;
			basketData.trips['default'].items[data.key] = data; // Key for locally stored 

			commit('SET_BASKET_DATA', basketData)
	
			dispatch('saveBasket');
			return { data: { success: true, type: "local" } };
		}
		
	},
	removeFromBasket({ commit, getters, dispatch }, key, trip = "default") { // Update to take key
		// Remove item
		let basketData = getters.getBasketData;
		delete basketData.trips[trip].items[key];
		commit('SET_BASKET_DATA', basketData)

		// call saveBasket() to update localstorage
		dispatch('saveBasket');
	},
	setSelectedTrip({ commit }, tripID) {
		commit("SET_SELECTED_TRIP", tripID);
	},
	
	setSelectedFlight({ commit }, { outboundKey, returnKey, fareData, airline, origin, destination, departure }) {
		commit("SET_SELECTED_FLIGHT", { outboundKey, returnKey, fareData, airline, origin, destination, departure });
	},
	setSelectedTour({ commit }, { tourID, departureID, roomCode, date, fareKey }) {
		commit("SET_SELECTED_TOUR", { tourID, departureID, roomCode, date, fareKey });
	}
	
}
  
export const basket = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};