const PAX_INFANT_MAX_AGE = 2;
const PAX_ADULT_MIN_AGE = 12;
const SUPPORT_EMAIL = "contact@purpose.travel"

function supportEmailLink(linkText = false) {
	if(!linkText) {
		linkText = SUPPORT_EMAIL;
	}
	let link = `<a href="mailto:${SUPPORT_EMAIL}">${linkText}</a>`;
	return link;
}

export default {
	PAX_INFANT_MAX_AGE,
	PAX_ADULT_MIN_AGE,
	SUPPORT_EMAIL,
	supportEmailLink,
}