import Vue from 'vue'
import Vuex from 'vuex'

import { account } from '@/store/modules/account.js'
import { alert } from '@/store/modules/alert.js'
import { flightsSearch } from '@/store/modules/flightsSearch.js'
import { toursSearch } from '@/store/modules/toursSearch.js'
import { basket } from '@/store/modules/basket.js'
import { blogs } from '@/store/modules/blogs.js'
import { trip } from '@/store/modules/trip.js'

Vue.use(Vuex)

export const store = new Vuex.Store({
	modules: {
		account,
		alert,
		flightsSearch,
		toursSearch,
		basket,
		blogs,
		trip
	}
})
