import apiClient from '@/helpers/axios.js';

export default {
	// Blogs
	latestPosts() {
		return apiClient.get('/cms/news/');
	},
	getPost(id) {
		return apiClient.get('/cms/news/' + id);
  	},

	// Mission page
	getMissionPage() { // Single page only - hardcoded ID?
		return apiClient.get('/cms/pages/43');
	},

	// Get page by ID
	getPage(id) {
		return apiClient.get('/cms/pages/' + id);
	},

	// Country page
	getCountry(countryCode) {
		return apiClient.get('/cms/country/' + countryCode);
	},
	getAllCountries() {
		return apiClient.get('/cms/pages?template=country');
	}
}
