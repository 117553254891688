import axios from 'axios';
import router from '@/router';
import Vue from "vue";

// Load axios as global 
const apiClient = axios.create({
	baseURL: process.env.VUE_APP_BASE_URL + `/ajax`,
	// "https://purpose.s0.oweb.ly/ajax",
	withCredentials: false, 
	headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json',
	},
	timeout: 100000
})

apiClient.interceptors.request.use(request => {
	if(process.env.NODE_ENV === "development") {
		console.log("Config set to https mode: " + process.env.VUE_APP_HTTPS);
		console.log('Starting Request', JSON.stringify(request, null, 2))
	}
	return request;
})
  
apiClient.interceptors.response.use(
	response => {
		let stringResponse = JSON.stringify(response, null, 2);
		if(process.env.NODE_ENV === "development") {
			console.log('Response:', stringResponse);
		}
		// Handle properly handled errors from backend (should always be a message relevant to the user)
		if(response.data.hasOwnProperty("success") && !response.data.success) { // If there is a success property, and it is false
			if(response.data.errors && response.data.errors.display) {
				let message = "";
				if(Array.isArray(response.data.errors.display)) {
					message = response.data.errors.display.join(", ");
				} else {
					message = response.data.errors.display;
				}
				if(process.env.NODE_ENV === "development") {
					console.log("Request errors: " + message);
				}
				Vue.prototype.$rollbar.error("Successful return, error should be used for display/information purposes for the user. " + stringResponse);
				return Promise.reject(message);
			}
			if(response.data.message) { // TODO: No longer used?
				if(process.env.NODE_ENV === "development") {
					console.log("Request error message: " + response.data.message);
				}
				Vue.prototype.$rollbar.error(stringResponse);
				return Promise.reject(response.data.message); // Return message from data
			} else {
				if(process.env.NODE_ENV === "development") {
					console.error("Invalid response from backend: " + response);
				}
				Vue.prototype.$rollbar.error("This is never hit anymore, I don't think. " + stringResponse);
				return Promise.reject("Something went wrong. Error code 1."); // No message provided, generic message instead.
			}
		} else if(response.data != "" && !isValidResponse(response.data)) {
			if(process.env.NODE_ENV === "development") {
				console.error("Invalid response from backend: " + response.data);
			}
			Vue.prototype.$rollbar.error("Invalid JSON returned in response. Probably backend issue. Unexpected error. " + stringResponse);
			return Promise.reject("Something went wrong. Error code 2.")
		} 
		return response;
	},
	error => {
		// Handle actual errors. Transform errors into user relevant messages based on status codes
		let stringResponse = JSON.stringify(error, null, 2);
		if(process.env.NODE_ENV === "development") {
			console.log('Response Error:', stringResponse);
		}
		Vue.prototype.$rollbar.error("Actual response error caught by axios helper. " + stringResponse);
		if(error.response) {
			switch (error.response.status) {
				case 401: 
					router.push({ 
						name: 'Login', 
						query: { 
							redirect: window.location.pathname 
						}
					});
					return Promise.reject("User not authorized");
				break;
				// Other error codes?
			}
		}
		if(error.message == 'cancel') { // The request was cancelled by the user. Should not return any response or error, so that the .then() chain does not do anything.
			return false;
		}
		// If error still not handled, display generic 'Something went wrong' message - as this is likely not a user-friendly error.
		let msg = (error.data && error.data.message) || error.statusText || error.message;
		if(process.env.NODE_ENV === "development") {
			console.log("Actual error: " + msg);
			console.error(error);
		}
		return Promise.reject("Something went wrong."); //Generic message for user ?
	}
);

function isValidResponse(data){
	if(data.constructor === Array) {
		return true;
	}
	if(data && typeof data === "object") {
		return true;
	}
    try {
        var o = JSON.parse(data);

        // Handle non-exception-throwing cases:
        // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
        // but... JSON.parse(null) returns null, and typeof null === "object", 
        // so we must check for that, too. Thankfully, null is falsey, so this suffices:
        if (o && typeof o === "object") {
            return true;
        }
    }
    catch (e) { }

    return false;
};

export default apiClient;