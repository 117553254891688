import apiClient from '@/helpers/axios.js';
import { store } from '../store/index';

export const tripService = {
	saveToTrip,
	saveToTripRecursive,
	fetchTrips,
	fetchTrip,
	fetchTripItems,
	modifyTripItem,
	createTrip,
	editTrip,
	deleteTrip,
	savePassenger,
	editPassenger,
	removePassenger,
	addRelatedPassenger,
	fetchTripPassengers,
	fetchUserPassengers,
	checkFlightsValidity,
	confirmFlightsChange,
	removeTripItem,
	fetchBookedFlightDetails,
	fetchBookedTourDetails,
	fetchPaymentItems,
};

function saveToTrip(data) {
	return apiClient.post('/save_items', data, {
		headers: store.getters['account/getAuthHeader']
	});
}

// The data given to this function, will always be sent to the same trip. If multiple trip IDs are given inside the data, it will send all items to the FIRST trip ID
function saveToTripRecursive(data, pointer = 0, responses = []) {
	var localData = localStorage.getItem('trips'); // TODO: UPDATE this local data fetch to be a basket.js function.
	localData = JSON.parse(localData);
	
	return apiClient.post('/save_items', data[pointer], {
		headers: store.getters['account/getAuthHeader']
	}).then(
		response => {
			responses.push(response);
			// Wipe that trip from local basket
			delete localData.trips.default.items[data[pointer].data.outbound_key]; 
			localStorage.setItem('trips', JSON.stringify(localData));
			if(pointer >= data.length - 1) { // Exit when all data has been sent (point reaches data array length)
				return responses;
			} else {
				pointer = pointer + 1;
				if(data[pointer].trip_id == false) {
					data[pointer].trip_id =  response.data.trip_id ? response.data.trip_id : false; // Set the trip ID based on the previous call returned trip_id (to ensure we are pushing all data to the same trip).
				}
				return saveToTripRecursive(data, pointer, responses) // Recursive call, until all data is sent.
			}
		},
		error => {
			responses.push(error);
			delete localData.trips.default.items[data[pointer].data.outbound_key]; 
			localStorage.setItem('trips', JSON.stringify(localData));
			if(pointer >= data.length - 1) { // Exit when all data has been sent (point reaches data array length)
				return responses;
			} else {
				pointer = pointer + 1;
				if(data[pointer].trip_id == false) {
					data[pointer].trip_id = error.data.trip_id ? error.data.trip_id : false; // Set the trip ID based on the previous call returned trip_id (to ensure we are pushing all data to the same trip).
				}
				return saveToTripRecursive(data, pointer, responses) // Recursive call, until all data is sent.
			}
		});
}

function fetchTrips() {
	return apiClient.post('/fetch_trips', null, {
		headers: store.getters['account/getAuthHeader']
	});
}
function fetchTrip(tripID) {
	let data = { trip_id: tripID };
	return apiClient.post('/fetch_trip', data, {
		headers: store.getters['account/getAuthHeader']
	});
}
function fetchTripItems(trip_id, trip_item_id = false) {
	var data = { 
		trip_id: trip_id,
		trip_item_id: trip_item_id,
	}
	
	return apiClient.post('/fetch_trip_items', data, {
		headers: store.getters['account/getAuthHeader']
	});
}
function modifyTripItem(tripID, tripItemID, updates) {
	var data = { 
		trip_id: tripID,
		trip_item_id: tripItemID,
		updates: updates,
	}
	
	return apiClient.post('/modify_trip_item', data, {
		headers: store.getters['account/getAuthHeader']
	});
}
// Data params: tripName
function createTrip(params = false) {
	var data = { data: {} };
	if(params) {
		data.data.name = params.tripName
	}
	return apiClient.post('/create_trip', data,
		{ headers: store.getters['account/getAuthHeader']}
	);
}
// Data params: tripName
function editTrip(tripId, params) {
	let formData = new FormData();
	// var data = { trip_id: tripId, data: {} };
	// if(params) {
	// 	data.data.name = params.tripName
	// 	if(params.tripImage) {
	// 		console.log(params.tripImage);
	// 		data.data.header_image = params.tripImage;
	// 	}
	// }
	if(params.tripImage) {
		formData.append('image', params.tripImage, params.tripImage.name);
	}
	if(params.tripName) {
		formData.append('name', params.tripName);
	}
	formData.append('trip_id', tripId);

	// // Display the key/value pairs
	// for (var pair of formData.entries()) {
	// 	console.log(pair[0]+ ', ' + pair[1]); 
	// }
	let headers = store.getters['account/getAuthHeader'];
	headers['Content-Type'] = "multipart/form-data";
	return apiClient.post('/modify_trip', formData,
		{ headers: headers }
	);
}
function deleteTrip(tripId) {
	var data = { trip_id: tripId };
	
	return apiClient.post('/delete_trip', data,
		{ headers: store.getters['account/getAuthHeader']}
	);
}
// SavePassenger does two things automatically - create new Pax AND assign it the the given trip+trip_item
function savePassenger(id = false, trip_id, trip_item_id, newPax = false, relatedTripItemData = false) {
	var data = { 
		id: id, 
		trip_id: trip_id, 
		trip_item_id: trip_item_id,
		data: newPax
	}

	if(relatedTripItemData) {
		if(relatedTripItemData.relatedAdult) {
			data.related_passenger_id = relatedTripItemData.relatedAdult;
		}
	}
	return apiClient.post('/save_passenger', data, {
		headers: store.getters['account/getAuthHeader']
	});
}
// This edits the passenger based on the passenger ID - therefore will update the passenger details EVERYWHERE that that pax is used (e.g. multiple different flights)
function editPassenger(passenger_id, pax_data) { 
	var data = { 
		passenger_id: passenger_id, 
		data: pax_data
	}
	
	return apiClient.post('/modify_passenger', data, {
		headers: store.getters['account/getAuthHeader']
	});
}
// removePassenger deletes the link between the passenger and the Trip Item (it does NOT delete the passenger)
function removePassenger(passenger_id, trip_id, trip_item_id) {
	var data = { 
		passenger_id: passenger_id, 
		trip_id: trip_id,
		trip_item_id,
	}
	
	return apiClient.post('/remove_passenger', data, {
		headers: store.getters['account/getAuthHeader']
	});
}
// Adds a "related passenger" to a pax. E.g. the adult associated with an infant for a flight.
function addRelatedPassenger(passenger_id, trip_id, trip_item_id, related_passenger_id) { // This edits the passenger based on the passenger ID - therefore will update the passenger details EVERYWHERE that that pax is used (e.g. multiple different flights)
	var data = { 
		passenger_id: passenger_id, 
		trip_id: trip_id,
		trip_item_id,
		related_passenger_id,
	}
	
	return apiClient.post('/add_related_passenger', data, {
		headers: store.getters['account/getAuthHeader']
	});
}
// Fetch all passengers associated with a trip
function fetchTripPassengers(trip_id, trip_item_id = false) {
	var data = { 
		trip_id: trip_id,
	}
	if(trip_item_id) {
		data.trip_item_id = trip_item_id;
	}
	
	return apiClient.post('/fetch_passengers', data, {
		headers: store.getters['account/getAuthHeader']
	});
}
// Fetches all passengers related to a user
function fetchUserPassengers() {
	return apiClient.get('/get_users_pax', {
		headers: store.getters['account/getAuthHeader']
	});
}
// Checks the validity for all flights on a trip. 
function checkFlightsValidity(tripID, tripItemIDs = false) {
	var data = { 
		trip_id: tripID,
		trip_item_ids: "",
	}
	if(tripItemIDs.length == 0) {
		// Don't fire the endpoint, as the user provided no trip items. Default of false not provided, which means all trip items.
		return Promise.resolve(true);
	} else if(tripItemIDs.length >= 0) {
		// Fire endpoint on defined trip items
		data.trip_item_ids = tripItemIDs.join(",");
	} else if(tripItemIDs == false) {
		// Fire endpoint without trip items defined (which does all trip items on the booking)
		trip_item_ids == "";
	} 

	return apiClient.post('/flight_check_price', data, {
		headers: store.getters['account/getAuthHeader']
	});
}
// Confirms a change as given from checkFlightsValidity
function confirmFlightsChange(tripID, tripItemID) {
	var data = { 
		trip_id: tripID,
		trip_item_ids: tripItemID, // This can be comma separated list if multiple
	}
	
	return apiClient.post('/flight_update_price', data, {
		headers: store.getters['account/getAuthHeader']
	});
}
// Checks the validity for all flights on a trip. 
function removeTripItem(tripID, tripItemID) {
	var data = { 
		trip_id: tripID,
		trip_item_id: tripItemID,
	}
	
	return apiClient.post('/remove_trip_item', data, {
		headers: store.getters['account/getAuthHeader']
	});
}
// Gets the details for a booked flight(s) - eTicket, terminal, gate, etc
function fetchBookedFlightDetails(tripID, tripItemID) {
	var data = { 
		trip_id: tripID,
		trip_item_ids: tripItemID ? tripItemID : "",
	}
	
	return apiClient.post('/flight_get_booking', data, {
		headers: store.getters['account/getAuthHeader']
	});
}
// Gets the details for a booked tour(s)
function fetchBookedTourDetails(tripID, tripItemID) {
	var data = { 
		trip_id: tripID,
		trip_item_id: tripItemID ? String(tripItemID) : "",
	}
	
	return apiClient.post('/tour_get_bookings', data, {
		headers: store.getters['account/getAuthHeader']
	});
}
// Get the related trip items from a payment ID
function fetchPaymentItems(paymentID) {
	return apiClient.get('/get_payment_items/' + paymentID, {
		headers: store.getters['account/getAuthHeader']
	});
}