import Vue from "vue";

const state = {
    type: null,
    message: null
};

const actions = {
    success({ commit }, message) {
		message = '<span class="message">' + message + '</span>';
        commit('success', message);
    },
    error({ commit }, message) {
		message = '<span class="message">' + message + '</span>';
        commit('error', message);
    },
	info({ commit }, message) {
		message = '<span class="message">' + message + '</span>';
        commit('info', message);
    },
	warning({ commit }, message) {
		message = '<span class="message">' + message + '</span>';
        commit('warning', message);
    },
    clear({ commit }) {
        commit('clear');
    }
};

const mutations = {
    success(state, message) {
		let instance = Vue.$toast.open(message);
    },
    error(state, message) {
		let instance = Vue.$toast.open({
			message: message,
			type: 'error',
		});
    },
	warning(state, message) {
		let instance = Vue.$toast.open({
			message: message,
			type: 'warning',
		});
    },
	info(state, message) {
		let instance = Vue.$toast.open({
			message: message,
			type: 'info',
		});
    },
    clear(state) {
        Vue.$toast.clear();
    }
};

export const alert = {
    namespaced: true,
    state,
    actions,
    mutations
};