
export default {
	validatePassword,
	validEmail,
	// validPhone
}

/* Checks the password and password confirmation meet the validation requirements
	Returns errors 
*/
function validatePassword(password, passwordReenter) {
	let passwordErrors = [];
	let passwordReenterErrors = [];
	if(!password || password == null) {
		passwordErrors.push('Password is required');
	}
	if(password.length < 6) {
		passwordErrors.push('Password must be 8 characters minimum');
	}
	if(!passwordReenter || passwordReenter == null) {
		passwordReenterErrors.push('Password confirmation is required');
	}
	if(password != passwordReenter) {
		passwordReenterErrors.push('Passwords do not match');
	}

	return { passwordErrors, passwordReenterErrors };
	
}

/**
 * Validates an email string
 *
 * @param {string} email the email to validate
 * @param {return} return returns boolean validated or not
 * @public This is a public method
*/
function validEmail(email) {
	// Regex for email validation that only allows the symbols + - and _ in the username portion. Also allows multi-level domains e.g. my.subdomain.example.co.uk
	var re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/;
	return re.test(email);
}
