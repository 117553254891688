import account from '@/helpers/account.js';

export default {
	/**
	 * Validates a saved traveller to ensure it has all required fields etc.
	 *
	 * @param {object} traveller the traveller to validate
	 * @return an array of errors, empty if	valid
	 * @public This is a public method
	*/
	validateTraveller(traveller) {
		let errors = {};
		let firstNameErrors = [];
		if(traveller.data.firstName == null || traveller.data.firstName == "") {
			firstNameErrors.push('You must enter a first name.');
		}
		var hasNumber = /\d/;
		if(hasNumber.test(traveller.data.firstName)) {
			firstNameErrors.push('Name must not contain numbers.');
		}
		let lastNameErrors = [];
		if(traveller.data.lastName == null || traveller.data.lastName == "") {
			lastNameErrors.push('You must enter a last name.');
		}
		if(hasNumber.test(traveller.data.lastName)) {
			lastNameErrors.push('Name must not contain numbers.');
		}
		let genderErrors = [];
		if(traveller.data.gender == null || traveller.data.gender == "") {
			genderErrors.push('You must select a gender.');
		}
		let emailErrors = [];
		if(traveller.data.email == null || traveller.data.email == "") {
			emailErrors.push('You must enter an email.');
		}
		if(traveller.data.email != null && traveller.data.email != "" && !account.validEmail(traveller.data.email)) { // Only trigger this if not empty.
			emailErrors.push('Must be a valid email');
		}
		let phoneErrors = [];
		if(traveller.data.phone == null || traveller.data.phone.fullNumber == "") {
			phoneErrors.push('You must enter a phone number.');
		}
		let dobErrors = [];
		let dobParse = Date.parse(traveller.data.dob);
		if(isNaN(dobParse) || traveller.data.dob == null || traveller.data.dob == "") {
			dobErrors.push('You must enter a date of birth.');
		}
		let passportNumberErrors = [];
		if(traveller.data.passportNumber == null || traveller.data.passportNumber == "") {
			passportNumberErrors.push('You must enter a passport number.');
		}
		let countryOfIssueErrors = [];
		if(traveller.data.countryOfIssue == null || traveller.data.countryOfIssue == "") {
			countryOfIssueErrors.push('You must enter a country of issue.');
		}
		let expiryDateErrors = [];
		if(traveller.data.passportExpiry == null || traveller.data.passportExpiry == "") {
			expiryDateErrors.push('You must enter a expiry date.');
		}

		// // No, these are NOT required (so the user can add another pax and come back)
		// let relatedAdultErrors = [];
		// if(this.paxIsInfant) {
		// 	if(!this.relatedAdult) {
		// 		relatedAdultErrors.push('You must selected a related adult.')
		// 	}
		// }

		errors.firstName = firstNameErrors;
		errors.lastName = lastNameErrors;
		errors.gender = genderErrors;
		errors.email = emailErrors;
		errors.phone = phoneErrors;
		errors.dob = dobErrors;
		errors.passport_number = passportNumberErrors;
		errors.issue = countryOfIssueErrors;
		errors.expiry = expiryDateErrors;
		// if(relatedAdultErrors.length > 0) {
		// 	errors.set('related_adult', relatedAdultErrors);
		// }
		return errors;
	}
	
}
