<template>
	<div class="search-type">
		<div class="title">{{ title }}</div>

		<div class="options">
			<div class="option flight" @click="flightSearch()">
				<div class="icon-holder">
					<img src="@/assets/search-type-select/flight-icon.png" alt="Flights Icon">
				</div>
				<div class="text">Flights</div>
			</div>
			<!-- <router-link :to="{ name: 'Flights Locations' }">
				<div class="option hotel">
					<div class="icon-holder">
						<img src="@/assets/search-type-select/hotel-icon.png" alt="Flights Icon">
					</div>
					<div class="text">Hotels</div>
				</div>
			</router-link>
			<router-link :to="{ name: 'Flights Locations' }">
				<div class="option flight">
					<div class="icon-holder">
						<img src="@/assets/search-type-select/car-hire-icon.png" alt="Flights Icon">
					</div>
					<div class="text">Car Hire</div>
				</div>
			</router-link> -->
			<div class="option tour"  @click="tourSearch()">
				<div class="icon-holder">
					<img src="@/assets/search-type-select/tour-icon.png" alt="Tours Icon">
				</div>
				<div class="text">Tours</div>
			</div>
		</div>
	</div>
</template>

<script>
	import router from '@/router';

	export default {
		name: 'SearchTypeSelect',
		props: {
			title: {
				type: String,
				default: "Select your travel style"
			},
			tripID: {
				default: false,
			},
		},
		methods: {
			flightSearch() {
				if(this.tripID) {
					router.push({
						name: "Flights Locations",
						query: {
							trip_id: this.tripID,
						}
					});
				} else {
					router.push({
						name: "Flights Locations",
					})
				}
			},
			tourSearch() {
				if(this.tripID) {
					router.push({
						name: "Tours Keyword",
						query: {
							trip_id: this.tripID,
						}
					});
				} else {
					router.push({
						name: "Tours Keyword",
					})
				}
			}
		},
	}
</script>

<style scoped>
	.search-type {
		max-width: 300px;
		margin: 0 auto;
		text-align: center;
	}
	.search-type .title {
		font-weight: 600;
		font-size: 20px;
		color: #1d1d1d;
	} 
	.search-type .options {
		padding-top: 30px;
	}
	.search-type .options a {
		color: #3A3F4B ;
	}
	.search-type .options .option {
		width: 50%;
		text-align: center;
		display: inline-block;
		margin: 0 auto;
		margin-bottom: 29px;
	}
	.search-type .option .icon-holder {
		display: inline-block;
		width: 96px;
		height: 96px;
		border: 1px solid #E5E5E5;
		border-radius: 48px;
	}
	.search-type .option .icon-holder img {
		width: 50px;
		margin: 0 auto;
		display: block;
		position: relative;
		top: 50%;
		transform: translateY(-50%);
	}
	.search-type .option.hotel img {
		width: 53px;
	}
	.search-type .option .text {
		font-weight: 700;
		margin-top: 5px;
	}
</style>
