<template>
	<router-link :to="{ name: 'Home' }">
		<img :src="getIcon(icon)" alt="Home Icon" class="top-left-menu-icon" :class="icon"/>
	</router-link>
</template>

<script>
	export default {
		props: {
			icon: {
				type: String,
				default: "purpose-logo",
			}
		},
		methods: {
			getIcon: (icon) => {
				if(icon == "purpose-logo") {
					return require('../assets/menu/home-purpose-logo.png');
				}
				return require('../assets/menu/home-purpose-logo.png');
			}
		}
	}
</script>

<style scoped>
	.top-left-menu-icon {
		left: 22px;
		top: 32px;
		z-index: 2;
		position: absolute;
	}
	.top-left-menu-icon.purpose-logo,
	.top-left-menu-icon.main {
		width: 43px;
	}
</style>